import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import ReactPlayer from 'react-player/lazy';

import { Video } from '../../types/carousel';
import useIsMobile from '../../hooks/useIsMobile/useIsMobile';
import MediaArrow from '../MediaArrow/MediaArrow';
import { useSwipeable } from 'react-swipeable';
import { ArrowVariant } from '../../types/index.js';

import styles from './MediaVideo.module.scss';

const node = document.getElementById('video')!;

const TOUR_ID_PLACEHOLDER = ':tourId';
const VIDEO_ID_PLACEHOLDER = ':videoId';

const TourBuilderClipUrl = `https://stream.tourbuilder.cloud/clips/${TOUR_ID_PLACEHOLDER}/${VIDEO_ID_PLACEHOLDER}/master.m3u8`;

interface MediaVideoProps {
  arrowVariant: ArrowVariant;
  className?: string;
  cssVariables?: CSSProperties;
  onNavigateLeft?: () => void;
  onNavigateRight?: () => void;
  showArrows: boolean;
  source: Video;
  tourId: string;
}

export default function MediaVideo({
  arrowVariant,
  className,
  cssVariables = {},
  onNavigateLeft,
  onNavigateRight,
  showArrows,
  source,
  tourId,
}: MediaVideoProps) {
  const isMobile = useIsMobile();
  const swipeHandlers = useSwipeable({
    onSwipedRight: isMobile && onNavigateLeft ? onNavigateLeft : undefined,
    onSwipedLeft: isMobile && onNavigateRight ? onNavigateRight : undefined,
  });

  const url =
    source.type === 'tourbuilder'
      ? TourBuilderClipUrl.replace(TOUR_ID_PLACEHOLDER, tourId).replace(
          VIDEO_ID_PLACEHOLDER,
          source.videoId
        )
      : source.url;

  return ReactDOM.createPortal(
    <div
      className={`${styles.container} ${className}`}
      {...swipeHandlers}
      style={cssVariables}
    >
      {onNavigateLeft && showArrows ? (
        <MediaArrow
          direction="left"
          arrowVariant={arrowVariant}
          onNavigate={onNavigateLeft}
        />
      ) : null}
      <div className={styles.videoContainer}>
        <ReactPlayer controls url={url} width="100%" height="100%" />
      </div>
      {onNavigateRight && showArrows ? (
        <MediaArrow
          direction="right"
          arrowVariant={arrowVariant}
          onNavigate={onNavigateRight}
        />
      ) : null}
    </div>,
    node
  );
}
