import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { ThemeProps } from '../Theme';

import { MenuItemContentProvider } from './hooks/useMenuItemContent/useMenuItemContent';
import { useAppSelector } from '../../hooks/redux';
import useIsMobile from '../../hooks/useIsMobile/useIsMobile';
import { useNavigateToPhoto } from '../../hooks/useNavigateToPhoto/useNavigateToPhoto';
import { useNavigateToVideo } from '../../hooks/useNavigateToVideo/useNavigateToVideo';
import useTourFunctionality from '../../hooks/useTourFunctionality/useTourFunctionality';
import useURLParam, {
  OverlayURLParam,
} from '../../hooks/useURLParam/useURLParam';

import BottomBar from './components/BottomBar/BottomBar';
import Chat from './components/Chat/Chat';
import FloorPlan from '../../components/FloorPlan/FloorPlan';
import FloorPlanMobileControls from './components/FloorPlanMobileControls/FloorPlanMobileControls';
import MediaImage from '../../components/MediaImage/MediaImage';
import MediaImageControls from '../../components/MediaImageControls/MediaImageControls';
import MediaVideo from '../../components/MediaVideo/MediaVideo';
import StartScreen from '../../components/StartScreen/StartScreen';
import UnitInfo from './components/UnitInfo/UnitInfo';
import useFireInitialAnalyticsEvents from '../../hooks/useFireInitialAnalyticsEvents/useFireInitialAnalyticsEvents';
import GlobalCss from './GlobalCss';

import styles from './GreystarUnitTheme.module.scss';

export default function GreystarUnitTheme({
  theme,
  tour,
  cssVariables,
}: ThemeProps) {
  const disableStartScreen =
    useURLParam(OverlayURLParam.START_SCREEN) === 'false';
  const [showStartScreen, setShowStartScreen] = useState(
    tour.startScreen.show && !disableStartScreen
  );
  const { logo } = useTourFunctionality(tour, theme);
  const media = useAppSelector((s) => s.media);
  const isMobile = useIsMobile();
  const isMobilePortrait = useIsMobile('portrait');

  const fireInitialAnalyticsEvents = useFireInitialAnalyticsEvents();

  useEffect(() => {
    if (!showStartScreen) {
      fireInitialAnalyticsEvents();
    }
  }, [fireInitialAnalyticsEvents, showStartScreen]);

  const floorPlanModeEnabled = window.location.href.endsWith('&f');

  const welcomeMessage = useMemo(() => {
    if (!floorPlanModeEnabled) {
      return undefined;
    }

    try {
      JSON.parse(tour.unitTour.floorPlanType);

      return undefined;
    } catch (error) {}

    return typeof tour.unitTour.floorPlanType === 'string'
      ? `<p>Floorplan ${tour.unitTour.floorPlanType}</br></p><p>Welcome to our 360° virtual tour</p>`
      : undefined;
  }, [floorPlanModeEnabled, tour.unitTour.floorPlanType]);

  const { navigateToPhoto: navigateToPhotoLeft } = useNavigateToPhoto(
    'left',
    tour.carousel.image
  );
  const { navigateToPhoto: navigateToPhotoRight } = useNavigateToPhoto(
    'right',
    tour.carousel.image
  );
  const { navigateToVideo: navigateToVideoLeft } = useNavigateToVideo(
    'left',
    tour.carousel.video
  );
  const { navigateToVideo: navigateToVideoRight } = useNavigateToVideo(
    'right',
    tour.carousel.video
  );

  return (
    <>
      <GlobalCss />
      <MenuItemContentProvider>
        <div
          data-cy="gs-unit-theme"
          id="theme"
          style={{ '--controls-border-radius': '50px' } as CSSProperties}
          className={styles.container}
        >
          {!isMobile && tour.unitTour.floorPlanType !== '' && (
            <UnitInfo
              unitInfo={tour.unitTour}
              joinPrimaryDetails={isMobile}
              hideUnitInformation={false}
            />
          )}
          <Chat clientId={tour.client._id} themeId={tour.themeId} />
          <BottomBar theme={theme} tour={tour} />
          {media.type === 'pano' && (
            <>
              <FloorPlan
                configs={tour.floorplan}
                sliders={tour.sliders}
                additionalControls={
                  isMobilePortrait ? (
                    <FloorPlanMobileControls theme={theme} tour={tour} />
                  ) : null
                }
              />
            </>
          )}
          {media.type === 'image' && (
            <>
              <MediaImage
                key={media.source.url}
                config={media}
                showArrows={tour.carousel.image.length > 1}
                arrowVariant="tb-pro"
                onNavigateLeft={navigateToPhotoLeft}
                onNavigateRight={navigateToPhotoRight}
                cssVariables={cssVariables}
              />
              <MediaImageControls tour={tour} />
            </>
          )}
          {media.type === 'video' && (
            <MediaVideo
              arrowVariant="tb-pro"
              cssVariables={cssVariables}
              onNavigateLeft={navigateToVideoLeft}
              onNavigateRight={navigateToVideoRight}
              showArrows={tour.carousel.video.length > 1}
              source={media.source}
              tourId={tour._id}
            />
          )}
          {showStartScreen && (
            <StartScreen
              config={tour.startScreen}
              welcomeMessage={welcomeMessage}
              logo={logo}
              poweredBy={tour.poweredByLCP360}
              showAccessibilityStatement={true}
              onStart={() => {
                fireInitialAnalyticsEvents();
                setShowStartScreen(false);
              }}
            />
          )}
        </div>
      </MenuItemContentProvider>
    </>
  );
}
