import { useCallback, useRef } from 'react';
import { useAppSelector } from '../redux';
import useAnalyticsEvents from '../useAnalyticsEvents/useAnalyticsEvents';
import useViewer from '../useViewer/useViewer';

const useFireInitialAnalyticsEvents = () => {
  const analyticsEvents = useAnalyticsEvents();

  const { panoId } = useViewer();
  const media = useAppSelector((s) => s.media);

  const initialAnalyticsEventsFired = useRef(false);

  return useCallback(() => {
    if (
      initialAnalyticsEventsFired.current ||
      (media.type === 'pano' && !panoId)
    ) {
      return;
    }

    initialAnalyticsEventsFired.current = true;

    analyticsEvents.tourVisit();

    switch (media.type) {
      case 'pano':
        if (panoId) {
          analyticsEvents.panoramaChanged(panoId);
        }
        break;
      case 'image':
        analyticsEvents.imageGalleryView(
          media.imageId,
          media.source.url,
          media.imageTitle
        );
        break;
      case 'video':
        analyticsEvents.videoGalleryView(
          media.source.videoId,
          media.source.title
        );
        break;
    }
  }, [analyticsEvents, media, panoId]);
};

export default useFireInitialAnalyticsEvents;
