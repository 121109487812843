import { useFullscreen } from '../../../../hooks/useFullscreen/useFullscreen';
import ControlGroup from '../../../../components/ControlGroup/ControlGroup';

import { ReactComponent as FullscreenIcon } from './assets/fullscreen.svg';

import styles from './Fullscreen.module.scss';
import { FULLSCREEN_VIEW_CONTROL_ID } from '../../../../constants/ids';

export default function Fullscreen() {
  const { toggleFullscreen } = useFullscreen();

  return (
    <ControlGroup
      id={FULLSCREEN_VIEW_CONTROL_ID}
      className={styles.controlGroup}
      controls={[
        {
          display: (
            <FullscreenIcon aria-label="Full Screen" data-cy="fullScreen" />
          ),
          onClick: () => {
            toggleFullscreen();
          },
        },
      ]}
    />
  );
}
