import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { store } from './stores/store';
import { Viewer } from './Viewer/Viewer';
import { ViewerProvider } from './hooks/useViewer/useViewer';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import './index.scss';

declare global {
  interface Window {
    Cypress: unknown;
    onCypressInit?: () => void;
  }
}

Sentry.init({
  environment: process.env.SENTRY_ENV || 'development',
  dsn: process.env.SENTRY_DSN_FRONT_END,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (window.Cypress) {
  window.onCypressInit?.();
}

Viewer.initViewer();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <ViewerProvider>
      <App />
    </ViewerProvider>
  </Provider>
);

reportWebVitals(console.log);
