import { CSSProperties, useEffect, useState, useMemo } from 'react';

import useURLParam, {
  OverlayURLParam,
} from '../../hooks/useURLParam/useURLParam';
import { MenuItemContentProvider } from './hooks/useMenuItemContent/useMenuItemContent';
import { ThemeProps } from '../Theme';
import { useAppSelector } from '../../hooks/redux';
import { useNavigateToPhoto } from '../../hooks/useNavigateToPhoto/useNavigateToPhoto';
import { useNavigateToVideo } from '../../hooks/useNavigateToVideo/useNavigateToVideo';
import BottomBar from './components/BottomBar/BottomBar';
import FloorPlan from '../../components/FloorPlan/FloorPlan';
import FloorPlanMobileControls from './components/FloorPlanMobileControls/FloorPlanMobileControls';
import GlobalCss from './GlobalCss';
import InfoHotspotModal from '../../components/InfoHotspotModal/InfoHotspotModal';
import MediaImage from '../../components/MediaImage/MediaImage';
import MediaImageControls from '../../components/MediaImageControls/MediaImageControls';
import MediaVideo from '../../components/MediaVideo/MediaVideo';
import StartScreen from '../../components/StartScreen/StartScreen';
import Stats from './components/Stats/Stats';
import useHotspots from '../../hooks/useHotspots/useHotspots';
import useIsMobile from '../../hooks/useIsMobile/useIsMobile';
import usePolygons from '../../hooks/usePolygons/usePolygons';
import useTourFunctionality from '../../hooks/useTourFunctionality/useTourFunctionality';
import useViewer from '../../hooks/useViewer/useViewer';
import VideoHotspotModal from '../../components/VideoHotspotModal/VideoHotspotModal';
import useFireInitialAnalyticsEvents from '../../hooks/useFireInitialAnalyticsEvents/useFireInitialAnalyticsEvents';

import styles from './TourBuilderUnitTheme.module.scss';

export default function TourBuilderUnitTheme({ theme, tour }: ThemeProps) {
  const disableStartScreen =
    useURLParam(OverlayURLParam.START_SCREEN) === 'false';
  const [showStartScreen, setShowStartScreen] = useState(
    tour.startScreen.show && !disableStartScreen
  );
  const { availableTabs, logo } = useTourFunctionality(tour, theme);
  const { panoId } = useViewer();
  const media = useAppSelector((s) => s.media);
  const isMobile = useIsMobile();
  const [hotspotData, clearHotspotData] = useHotspots(
    tour.hotspots,
    theme.styles.hotspots
  );
  usePolygons(tour.polygons);

  const fireInitialAnalyticsEvents = useFireInitialAnalyticsEvents();

  useEffect(() => {
    if (!showStartScreen) {
      fireInitialAnalyticsEvents();
    }
  }, [fireInitialAnalyticsEvents, showStartScreen]);

  // this should fix the grey screen issue upon page load some clients are seeing
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [showStartScreen, panoId]);

  const { navigateToPhoto: navigateToPhotoLeft } = useNavigateToPhoto(
    'left',
    tour.carousel.image
  );
  const { navigateToPhoto: navigateToPhotoRight } = useNavigateToPhoto(
    'right',
    tour.carousel.image
  );
  const { navigateToVideo: navigateToVideoLeft } = useNavigateToVideo(
    'left',
    tour.carousel.video
  );
  const { navigateToVideo: navigateToVideoRight } = useNavigateToVideo(
    'right',
    tour.carousel.video
  );

  const isMobilePortrait = useIsMobile('portrait');

  const floorPlanModeEnabled = window.location.href.endsWith('&f');

  const welcomeMessage = useMemo(() => {
    if (!floorPlanModeEnabled) {
      return undefined;
    }

    try {
      JSON.parse(tour.unitTour.floorPlanType);

      return undefined;
    } catch (error) {}
    return typeof tour.unitTour.floorPlanType === 'string'
      ? `<p>Floorplan ${tour.unitTour.floorPlanType}</br></p><p>Welcome to our 360° virtual tour</p>`
      : undefined;
  }, [floorPlanModeEnabled, tour.unitTour.floorPlanType]);

  return (
    <>
      <GlobalCss />
      <MenuItemContentProvider>
        <div
          data-cy="tb-unit-default-theme"
          id="theme"
          style={{ '--controls-border-radius': '50px' } as CSSProperties}
        >
          <Stats tour={tour} />
          {media.type === 'pano' && (
            <>
              <FloorPlan
                configs={tour.floorplan}
                sliders={tour.sliders}
                additionalControls={
                  isMobilePortrait ? (
                    <FloorPlanMobileControls theme={theme} tour={tour} />
                  ) : null
                }
              />
              {hotspotData?.type === 'info' ? (
                <InfoHotspotModal
                  data={hotspotData}
                  onClose={clearHotspotData}
                />
              ) : null}
              {hotspotData?.type === 'video' ? (
                <VideoHotspotModal
                  data={hotspotData}
                  onClose={clearHotspotData}
                />
              ) : null}
            </>
          )}
          {media.type === 'image' && (
            <>
              <MediaImage
                key={media.source.url}
                config={media}
                showArrows={tour.carousel.image.length > 1}
                arrowVariant={isMobile ? 'side-mobile' : 'bottom'}
                onNavigateLeft={navigateToPhotoLeft}
                onNavigateRight={navigateToPhotoRight}
              />
              <MediaImageControls tour={tour} />
            </>
          )}
          {media.type === 'video' && (
            <MediaVideo
              arrowVariant={isMobile ? 'side-mobile' : 'bottom'}
              className={styles.mediaVideo}
              onNavigateLeft={navigateToVideoLeft}
              onNavigateRight={navigateToVideoRight}
              showArrows={tour.carousel.video.length > 1}
              source={media.source}
              tourId={tour._id}
            />
          )}
          <BottomBar
            availableTabs={availableTabs}
            backgroundColor={theme.styles.menu.background}
            floorPlanModeEnabled={floorPlanModeEnabled}
            theme={theme}
            tour={tour}
          />
          {showStartScreen && (
            <StartScreen
              config={tour.startScreen}
              welcomeMessage={welcomeMessage}
              onStart={() => {
                fireInitialAnalyticsEvents();
                setShowStartScreen(false);
              }}
              logo={logo}
              poweredBy={tour.poweredByLCP360}
              showAccessibilityStatement={true}
            />
          )}
        </div>
      </MenuItemContentProvider>
    </>
  );
}
