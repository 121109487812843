import React, { useCallback, useEffect, useState, useRef } from 'react';

import clsx from 'clsx';
import { animated, useTransition } from '@react-spring/web';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Navigation } from 'swiper/modules';

import {
  Pano as CarouselPano,
  Carousel as Config,
} from '../../../../types/carousel';
import onEnter from '../../../../utils/onEnter/onEnter';
import { CAROUSEL_ID } from '../../../../constants/ids';
import { showPano } from '../../../../stores/slices/media';
import {
  getCarouselVisible,
  setCarouselVisible,
} from '../../../../stores/slices/ui';
import { useAppSelector, useAppDispatch } from '../../../../hooks/redux';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import useOnClickOutside from '../../../../hooks/useOnClickOutside/useOnClickOutside';
import useViewer from '../../../../hooks/useViewer/useViewer';
import ScrollArrow from './ScrollArrow';

import { ReactComponent as Close } from './assets/close.svg';
import logoLight from './assets/logo-light.png';
import logoDark from './assets/logo-dark.png';

import styles from './Carousel.module.scss';

interface CarouselProps {
  config: Config;
  themeType: 'light' | 'dark';
}

interface CarouselItemProps {
  title: string;
  onClick(): void;
  active: boolean;
}

const CarouselItem = React.memo(
  ({ title, onClick, active }: CarouselItemProps) => (
    <figure
      className={clsx(styles.item, active && styles.active)}
      onClick={onClick}
      onKeyDown={onEnter(onClick)}
      tabIndex={0}
    >
      <div>{title}</div>
    </figure>
  )
);

const UP_SCROLL_ARROW_CLASSNAME = 'carousel-up-scroll-arrow';
const DOWN_SCROLL_ARROW_CLASSNAME = 'carousel-down-scroll-arrow';

export default function Carousel({ config, themeType }: CarouselProps) {
  const media = useAppSelector((s) => s.media);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const isLandscape = useIsMobile('landscape');
  const visible = useAppSelector(getCarouselVisible);
  const toggleVisible = () => dispatch(setCarouselVisible(!visible));
  const closeOnOutsideClick = () => dispatch(setCarouselVisible(false));
  const { panoId, viewer } = useViewer();
  const [showUp, setShowUp] = useState(false);
  const [showDown, setShowDown] = useState(true);
  const slidesPerView = { desktop: 10, mobile: 5 };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isMobile ? dispatch(setCarouselVisible(false)) : null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPano = useCallback(
    (pano: CarouselPano) => {
      dispatch(showPano());

      if (panoId !== pano.panoid) {
        viewer?.setPano(pano.panoid, { pov: pano.pov });
      }
    },
    [dispatch, panoId, viewer]
  );

  const containerTransition = useTransition(visible, {
    from: { translateX: '100%' },
    enter: { translateX: '0%' },
    leave: { translateX: '100%' },
  });

  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, closeOnOutsideClick);

  return (
    <>
      {containerTransition(
        (style, item) =>
          item && (
            <animated.div
              className={styles.container}
              id={CAROUSEL_ID}
              style={style}
              data-cy="carousel"
              ref={containerRef}
            >
              <div
                className={styles.closeContainer}
                role="button"
                onClick={toggleVisible}
                data-cy="carousel-hamburger"
              >
                <Close />
              </div>
              <div className={styles.imageContainer}>
                {themeType === 'light' ? (
                  <img src={logoLight} alt="Logo Light" />
                ) : (
                  <img src={logoDark} alt="Logo Dark" />
                )}
              </div>
              <div className={styles.arrows}>
                <ScrollArrow
                  className={UP_SCROLL_ARROW_CLASSNAME}
                  direction="up"
                  show={showUp}
                />
                <ScrollArrow
                  className={DOWN_SCROLL_ARROW_CLASSNAME}
                  direction="down"
                  show={showDown}
                />
              </div>
              <Swiper
                className={styles.carousel}
                modules={[Mousewheel, Navigation]}
                id="carousel"
                direction={'vertical'}
                mousewheel={true}
                slidesOffsetAfter={30}
                slidesPerView={
                  isLandscape ? slidesPerView.mobile : slidesPerView.desktop
                }
                navigation={{
                  enabled: true,
                  nextEl: `.${DOWN_SCROLL_ARROW_CLASSNAME}`,
                  prevEl: `.${UP_SCROLL_ARROW_CLASSNAME}`,
                }}
                spaceBetween={19}
                onReachEnd={() => {
                  setShowDown(false);
                }}
                onReachBeginning={() => {
                  setShowUp(false);
                }}
                onFromEdge={() => {
                  setShowUp(true);
                  setShowDown(true);
                }}
              >
                {config.pano
                  .filter((pano) => pano.carousel)
                  .map((pano) => (
                    <SwiperSlide key={pano.panoid}>
                      <CarouselItem
                        title={pano.title}
                        active={media.type === 'pano' && panoId === pano.panoid}
                        onClick={() => setPano(pano)}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </animated.div>
          )
      )}
    </>
  );
}
