import { useEffect, useState } from 'react';

import useURLParam, {
  OverlayURLParam,
} from '../../hooks/useURLParam/useURLParam';
import { getCarouselVisible } from '../../stores/slices/ui';
import { MenuItemContentProvider } from './hooks/useMenuItemContent/useMenuItemContent';
import { ThemeProps } from '../Theme';
import { useAppSelector } from '../../hooks/redux';
import { useNavigateToPhoto } from '../../hooks/useNavigateToPhoto/useNavigateToPhoto';
import { useNavigateToVideo } from '../../hooks/useNavigateToVideo/useNavigateToVideo';
import Carousel from './components/Carousel/Carousel';
import GlobalCss from './GlobalCss';
import MediaImage from '../../components/MediaImage/MediaImage';
import MediaImageControls from '../../components/MediaImageControls/MediaImageControls';
import MediaVideo from '../../components/MediaVideo/MediaVideo';
import Pano from './components/Pano/Pano';
import ShareLive from './components/ShareLive/ShareLive';
import Sidebar from './components/Sidebar/Sidebar';
import StartScreen from './components/StartScreen/StartScreen';
import useIsMobile from '../../hooks/useIsMobile/useIsMobile';
import useTourFunctionality from '../../hooks/useTourFunctionality/useTourFunctionality';
import useViewer from '../../hooks/useViewer/useViewer';
import useFireInitialAnalyticsEvents from '../../hooks/useFireInitialAnalyticsEvents/useFireInitialAnalyticsEvents';

import styles from './LegacyTheme.module.scss';

export default function LegacyTheme({ theme, tour }: ThemeProps) {
  const disableStartScreen =
    useURLParam(OverlayURLParam.START_SCREEN) === 'false';
  const [showStartScreen, setShowStartScreen] = useState(
    tour.startScreen.show && !disableStartScreen
  );
  const { availableTabs, logo, isCarouselEnabled } = useTourFunctionality(
    tour,
    theme
  );
  const media = useAppSelector((state) => state.media);
  const visible = useAppSelector(getCarouselVisible);
  const hideRightMenu = Boolean(useURLParam(OverlayURLParam.HIDE_RIGHT_MENU));
  const hideShareLive = useURLParam(OverlayURLParam.HIDE_SHARE_LIVE);
  const { panoId } = useViewer();
  const isMobilePortrait = useIsMobile('portrait');
  const fireInitialAnalyticsEvents = useFireInitialAnalyticsEvents();

  useEffect(() => {
    if (!showStartScreen) {
      fireInitialAnalyticsEvents();
    }
  }, [fireInitialAnalyticsEvents, showStartScreen]);

  // this should fix the grey screen issue upon page load some clients are seeing
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [showStartScreen, panoId]);

  const { navigateToPhoto: navigateToPhotoLeft } = useNavigateToPhoto(
    'left',
    tour.carousel.image
  );
  const { navigateToPhoto: navigateToPhotoRight } = useNavigateToPhoto(
    'right',
    tour.carousel.image
  );
  const { navigateToVideo: navigateToVideoLeft } = useNavigateToVideo(
    'left',
    tour.carousel.video
  );
  const { navigateToVideo: navigateToVideoRight } = useNavigateToVideo(
    'right',
    tour.carousel.video
  );

  return (
    <>
      <GlobalCss />
      <MenuItemContentProvider>
        <div data-cy="v1-theme" id="theme">
          {showStartScreen && (
            <StartScreen
              config={tour.startScreen}
              onStart={() => {
                fireInitialAnalyticsEvents();
                setShowStartScreen(false);
              }}
              poweredBy={true}
              logo={logo}
              showAccessibilityStatement={true}
            />
          )}
          {!isMobilePortrait && !hideShareLive ? (
            <ShareLive
              clientId={tour.client._id}
              tourId={tour._id}
              themeId={tour.themeId}
            />
          ) : null}
          {!hideRightMenu ? <Sidebar tour={tour} theme={theme} /> : null}
          {media.type === 'pano' && (
            <Pano tour={tour} hideRightMenu={hideRightMenu} theme={theme} />
          )}
          {media.type === 'image' && (
            <>
              <MediaImage
                key={media.source.url}
                config={media}
                showArrows={tour.carousel.image.length > 1}
                arrowVariant="side"
                onNavigateLeft={navigateToPhotoLeft}
                onNavigateRight={navigateToPhotoRight}
              />
              <MediaImageControls tour={tour} />
            </>
          )}
          {media.type === 'video' && (
            <>
              <MediaVideo
                arrowVariant="side"
                className={
                  visible
                    ? styles.carouselVisibleVideo
                    : styles.carouselHiddenVideo
                }
                onNavigateLeft={navigateToVideoLeft}
                onNavigateRight={navigateToVideoRight}
                showArrows={tour.carousel.video.length > 1}
                source={media.source}
                tourId={tour._id}
              />
            </>
          )}
          {isCarouselEnabled && (
            <Carousel
              availableTabs={availableTabs}
              config={tour.carousel}
              tabColors={{
                default: theme.styles.carousel.tabs.icons.default.color,
                active: theme.styles.carousel.tabs.background,
                background: theme.styles.carousel.tabs.backgroundActive,
              }}
              tour={tour}
            />
          )}
        </div>
      </MenuItemContentProvider>
    </>
  );
}
