// Function that helps to determine if the background colour is light or dark.
// Use in combination with light and dark logos

export default function isDarkTheme(color: string) {
  if (color) {
    // breaks down the color value in RGB digits and returns as array of number
    const rgb: number[] = (color.match(/\d+/g) as string[]).map(Number);

    // calculates perceived brightness of the colour
    const luminance = 0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2];

    return luminance > 128 ? 'dark' : 'light';
  } else {
    return 'dark';
  }
}
