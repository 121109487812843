import { animated, useTransition } from '@react-spring/web';
import React, { useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import {
  MenuButton as IMenuButton,
  MenuItemType,
  Tour,
} from '../../../../types';
import useURLParam, {
  OverlayURLParam,
} from '../../../../hooks/useURLParam/useURLParam';

import { capitalizeWords } from '../../../../utils/capitalizeWords/capitalizeWords';
import { HamburgerIcon } from '../../../../components/Hamburger/Hamburger';
import { ICONS_CONFIG } from '../Menu/Menu';
import { MEET_URL } from '../../../../constants';
import appendParamsToUrl from '../../../../utils/appendParamsToUrl/appendParamsToUrl';
import ControlGroup from '../../../../components/ControlGroup/ControlGroup';
import useMenuItemContent from '../../hooks/useMenuItemContent/useMenuItemContent';
import useOnClickOutside from '../../../../hooks/useOnClickOutside/useOnClickOutside';
import useSource from '../../../../utils/useSource/useSource';
import useUTMParams from '../../../../hooks/useUTMParams/useUTMParams';

import { ReactComponent as Info } from '../../../LivcorTheme/components/ViewControls/assets/info.svg';

import styles from './MobileMenu.module.scss';
import {
  HAMBURGER_VIEW_CONTROL_ID,
  makeMenuButtonID,
} from '../../../../constants/ids';

interface MobileMenuProps {
  menuButtons: IMenuButton[];
  tour: Tour;
}

interface FontAwesomeIconProps {
  icon: string;
}

function FontAwesomeIcon({ icon }: FontAwesomeIconProps) {
  return <i className={clsx('fa', icon)} aria-hidden="true"></i>;
}

interface MenuButtonProps {
  text: string;
  icon: string;
  id: string;
  conversion: boolean;
  type: MenuItemType;
  onClose: () => void;
  onClick?: () => void;
}

const MenuButton = React.memo(
  ({ text, icon, id, type, conversion, onClose, onClick }: MenuButtonProps) => {
    const Icon = ICONS_CONFIG[icon] || Info;
    const isFontAwesomeIcon =
      icon.startsWith('fa-') && icon !== 'fa-map-marker' && icon !== 'fa-info';
    const { openMenuItem } = useMenuItemContent();

    const handleOnClick = () => {
      onClose();
      !onClick ? openMenuItem(id, type, conversion) : onClick();
    };

    return (
      <div
        className={styles.menuButton}
        id={makeMenuButtonID(id)}
        data-cy={`menu-button-${id}`}
        onClick={handleOnClick}
      >
        {isFontAwesomeIcon ? <FontAwesomeIcon icon={icon} /> : <Icon />}
        <span>{capitalizeWords(text)}</span>
      </div>
    );
  }
);

export default function MobileMenu({ menuButtons, tour }: MobileMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const utmParams = useUTMParams();
  const { sourceId } = useSource();
  const admin = useURLParam(OverlayURLParam.ADMIN);
  const trackingToken = useURLParam(OverlayURLParam.TRACKING_TOKEN);
  const hideShareLive = useURLParam(OverlayURLParam.HIDE_SHARE_LIVE) === 'true';

  const clientId = tour.client._id;
  const tourId = tour._id;
  const themeId = tour.themeId;

  const meetingUrlWithAdditionalParams = appendParamsToUrl(
    `${MEET_URL}/${themeId}/${clientId}/${tourId}`,
    {
      ...utmParams,
      sourceId,
      admin,
      tracking_token: trackingToken,
    }
  );

  const menuControls = useMemo(() => {
    return menuButtons
      .filter((menuButton) => menuButton.type !== 'fullscreen')
      .map((menuButton, idx) => {
        return {
          display: (
            <MenuButton
              text={menuButton.text}
              icon={menuButton.icon}
              id={menuButton.id}
              conversion={menuButton.conversion}
              type={menuButton.type}
              onClose={() => setIsOpen(false)}
              key={idx}
            />
          ),
        };
      });
  }, [menuButtons]);

  const transitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  return (
    <>
      <ControlGroup
        controls={[
          {
            display: <HamburgerIcon visible={isOpen} />,
            onClick: () => setIsOpen(!isOpen),
          },
        ]}
        id={HAMBURGER_VIEW_CONTROL_ID}
      />
      {transitions(
        (style, item) =>
          item && (
            <animated.div data-cy="view-controls" style={style}>
              <div className={styles.dropdownBackground}></div>
              <div className={styles.dropdown} ref={ref}>
                {menuControls.map(({ display }) => display)}
                {!hideShareLive && (
                  <MenuButton
                    text={'Share live'}
                    icon={'custom-share-live'}
                    id={'share-live'}
                    conversion={false}
                    type={'dialog'}
                    onClose={() => {
                      setIsOpen(false);
                    }}
                    onClick={() =>
                      window.open(meetingUrlWithAdditionalParams, '_blank')
                    }
                  />
                )}
              </div>
            </animated.div>
          )
      )}
    </>
  );
}
