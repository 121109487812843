import { useState } from 'react';

import { Tour } from '../../../../types';
import { useFullscreen } from '../../../../hooks/useFullscreen/useFullscreen';
import AutoRotate from '../../../../components/AutoRotate/AutoRotate';
import useTourFunctionality from '../../../../hooks/useTourFunctionality/useTourFunctionality';

import { ReactComponent as AutoRotatePlayIcon } from './assets/autorotate-play.svg';
import { ReactComponent as AutoRotatePauseIcon } from './assets/autorotate-pause.svg';
import { ReactComponent as FullscreenIcon } from './assets/fullscreen.svg';

import styles from './FloorPlanMobileControls.module.scss';
import { Theme } from '../../../../types/theme';

interface FloorPlanMobileControlsProps {
  theme: Theme;
  tour: Tour;
  isLandscape: boolean;
}

export default function FloorPlanMobileControls({
  theme,
  tour,
  isLandscape,
}: FloorPlanMobileControlsProps) {
  const [autoRotateEnabled, setAutoRotateEnabled] = useState(tour.autoRotate);
  const { hasFullscreenButton } = useTourFunctionality(tour, theme);
  const { isFullscreenEnabled, toggleFullscreen } = useFullscreen();

  return (
    <div className={styles.container}>
      <div className={styles.firstGroup}>
        <AutoRotate
          className={styles.autoRotate}
          enabled={autoRotateEnabled}
          onChange={setAutoRotateEnabled}
          tour={tour}
          playIcon={AutoRotatePlayIcon}
          pauseIcon={AutoRotatePauseIcon}
        />
        {hasFullscreenButton && isFullscreenEnabled ? (
          <FullscreenIcon
            className={styles.floorPlanMobileControl}
            onClick={() => toggleFullscreen()}
            data-cy="floor-plan-fullScreen"
          />
        ) : null}
      </div>
    </div>
  );
}
