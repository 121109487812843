import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export enum StatIndex {
  Visits = 0,
  Views = 1,
  Click = 2,
  Conversion = 3,
  Live = 4,
  Likes = 6,
}

interface IncreaseStatArguments {
  clientId: string;
  statId?: string;
  statIndex: StatIndex;
  tourId: string;
}

interface GetTotalTourStatsArguments {
  tourId: string;
}

interface TourTotalStats {
  stats: {
    _id: string;
    total: {
      clicks: number;
      conversions: number;
      crm: {
        emails: {
          clicked: number;
          opened: number;
          sent: number;
          unsubscribed: number;
        };
      };
      likes: number;
      live: number;
      meetings: number;
      views: number;
      visits: number;
    };
  };
}

export const statsApi = createApi({
  reducerPath: 'statsApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/stats' }),
  tagTypes: ['Stats'],
  endpoints: (builder) => ({
    increaseStat: builder.mutation<void, IncreaseStatArguments>({
      query: ({ clientId, statId = '', statIndex, tourId }) => ({
        url: `${clientId}/${statIndex}/${tourId}/${statId}`,
        method: 'GET',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Stats', id: arg.tourId },
      ],
    }),
    getTotalTourStats: builder.query<
      TourTotalStats,
      GetTotalTourStatsArguments
    >({
      query: ({ tourId }) => ({
        url: `total/${tourId}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [{ type: 'Stats', id: arg.tourId }],
    }),
  }),
});

export const { useIncreaseStatMutation, useGetTotalTourStatsQuery } = statsApi;
