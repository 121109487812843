import { Tour } from '../../types';
import useTheme from '../useTheme/useTheme';
import useTourFunctionality from '../useTourFunctionality/useTourFunctionality';
import useWindowSize from '../useWindowSize/useWindowSize';
import { useDebugValue, useMemo } from 'react';

interface LayoutConfiguration {
  menuButtonHeight: number;
  tourControlsHeight: number; // Zoom, Autorotate, Fullscreen combined
  switchToLandscapeThreshold: number;
}

export default function makeVerticalLayoutHook(config: LayoutConfiguration) {
  function useVerticalLayout(tour: Tour, includeTourControls: boolean = true) {
    const theme = useTheme();
    const { availableButtons } = useTourFunctionality(tour, theme);
    const windowSize = useWindowSize();

    const value = useMemo(() => {
      const menuHeight =
        config.menuButtonHeight *
        availableButtons
          .filter((menuButton) => menuButton.type !== 'fullscreen')
          .filter((button) => button.show).length;
      const additionalControlsHeight =
        (includeTourControls ? config.tourControlsHeight : 100) +
        config.switchToLandscapeThreshold;
      const neededHeight = menuHeight + additionalControlsHeight;
      return {
        isViewportTooLow:
          neededHeight > (windowSize?.height || document.body.clientHeight),
        menuHeight,
        neededHeight,
        additionalControlsHeight,
      };
    }, [availableButtons, windowSize?.height, includeTourControls]);

    useDebugValue(
      value,
      ({ isViewportTooLow, menuHeight, additionalControlsHeight }) => {
        let message;
        if (isViewportTooLow) {
          message = 'viewport is too low to fit';
        } else {
          message = 'viewport can fit';
        }
        return `${message} ${Math.floor(menuHeight)}px menu + ${Math.floor(
          additionalControlsHeight
        )}px controls`;
      }
    );

    return value;
  }

  return useVerticalLayout;
}
