import { ReactNode, useEffect, useState } from 'react';
import useViewer from '../../hooks/useViewer/useViewer';
import { FloorPlan as FloorPlanConfig, Slider } from '../../types';
import FloorPlanControls from './components/FloorPlanControls/FloorPlanControls';
import FloorPlanMap from './components/FloorPlanMap/FloorPlanMap';
import { VisibilityState } from './types';

import styles from './FloorPlan.module.scss';
import useIsMobile from '../../hooks/useIsMobile/useIsMobile';
import usePanosSliders from '../../hooks/usePanosSliders/usePanosSliders';

interface FloorPlanProps {
  configs: FloorPlanConfig[];
  sliders: Slider[];
  additionalControls?: ReactNode | ReactNode[];
  darkTheme?: boolean;
}

const FloorPlan = ({
  configs,
  sliders,
  additionalControls,
  darkTheme,
}: FloorPlanProps) => {
  const { viewer, panoId, pov } = useViewer();
  const isMobile = useIsMobile();

  const [mapVisibility, setMapVisibility] = useState<VisibilityState>(
    isMobile ? 'hidden' : 'visible'
  );

  const [floorplanConfig, setFloorplanConfig] = useState<FloorPlanConfig>();

  useEffect(() => {
    const currentFloorplan = configs.find((currentConfig) => {
      return currentConfig.hotspots.some(
        (hotspot) => hotspot.panoid === panoId
      );
    })!;

    setFloorplanConfig(currentFloorplan);
  }, [configs, panoId, viewer]);

  const [sliderOpacity, setPanoSliderOpacity] = usePanosSliders(sliders);

  if (!viewer || !panoId || !pov) {
    return null;
  }

  if (!floorplanConfig && !additionalControls && !setPanoSliderOpacity)
    return null;

  return (
    <div className={styles.floorPlan} data-cy="floor-plan">
      <FloorPlanControls
        onVisibilityChange={floorplanConfig && setMapVisibility}
        onSliderChange={
          setPanoSliderOpacity
            ? (value) => setPanoSliderOpacity(1 - value)
            : undefined
        }
        sliderValue={1 - sliderOpacity}
        additionalControls={additionalControls}
        mapVisibility={mapVisibility}
        darkTheme={darkTheme}
      />
      {floorplanConfig && (
        <>
          <FloorPlanMap
            currentPanoId={panoId}
            heading={pov.heading}
            hotspots={floorplanConfig.hotspots}
            mapImageUrl={floorplanConfig.img || floorplanConfig.image!.url}
            onPanoramaSelect={(selectedPanoId) => {
              if (panoId === selectedPanoId) {
                return;
              }

              viewer.setPano(selectedPanoId);
            }}
            visibility={mapVisibility}
          />
        </>
      )}
    </div>
  );
};

export default FloorPlan;
