import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { animated, useTransition } from '@react-spring/web';
import { Mousewheel, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import clsx from 'clsx';

import {
  Carousel as Config,
  CarouselItemType,
  Image as CarouselImage,
  Pano as CarouselPano,
  Video as CarouselVideo,
} from '../../../../types/carousel';
import { CAROUSEL_ID, TABS_ID } from '../../../../constants/ids';

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
  getCarouselVisible,
  setCarouselVisible,
} from '../../../../stores/slices/ui';
import {
  showImage,
  showPano,
  showVideo,
  Image,
  Video,
} from '../../../../stores/slices/media';
import useViewer from '../../../../hooks/useViewer/useViewer';
import ImageComponent from '../../../../components/Image/Image';
import onEnter from '../../../../utils/onEnter/onEnter';

import PillGroup, {
  Colors,
  PillConfig,
} from '../../../../components/PillGroup/PillGroup';
import ScrollArrow from './ScrollArrow';
import useAnalyticsEvents from '../../../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import useOnClickOutside from '../../../../hooks/useOnClickOutside/useOnClickOutside';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';

import { ReactComponent as FloorPlanIndicator } from './assets/floorplan-indicator.svg';
import { ReactComponent as PanoIcon } from './assets/3d.svg';
import { ReactComponent as PhotoIcon } from './assets/photo.svg';
import { ReactComponent as VideoIcon } from './assets/camera.svg';
import { ReactComponent as Close } from './assets/close.svg';
import logoLight from './assets/logo-light.png';
import logoDark from './assets/logo-dark.png';

import styles from './Carousel.module.scss';

interface CarouselItemProps {
  id: string;
  title: string;
  thumbnail: string;
  onClick(): void;
  active: boolean;
  hasFloorplan?: boolean;
}

const CarouselItem = React.memo(
  ({
    id,
    onClick,
    thumbnail,
    title,
    active,
    hasFloorplan,
  }: CarouselItemProps) => (
    <figure
      id={id}
      className={styles.item}
      onClick={onClick}
      onKeyDown={onEnter(onClick)}
      data-cy="carousel-item"
      tabIndex={0}
    >
      {hasFloorplan && (
        <FloorPlanIndicator className={styles.floorPlanIndicator} />
      )}
      <ImageComponent src={thumbnail} alt="Carousel item" />
      {active && (
        <div
          className={styles.active}
          data-cy="carousel-active-item-indicator"
        />
      )}
      {title && (
        <figcaption className={styles.title}>
          <span title={title}>{title}</span>
        </figcaption>
      )}
    </figure>
  )
);

interface CarouselProps {
  availableTabs: CarouselItemType[];
  config: Config;
  floorPlanPanoIds: string[];
  initialItemType: CarouselItemType;
  pillColors: Colors;
  themeType: 'light' | 'dark';
}

const UP_SCROLL_ARROW_CLASSNAME = 'carousel-up-scroll-arrow';
const DOWN_SCROLL_ARROW_CLASSNAME = 'carousel-down-scroll-arrow';

export default function Carousel({
  availableTabs,
  config,
  floorPlanPanoIds,
  initialItemType,
  pillColors,
  themeType,
}: CarouselProps) {
  const windowSize = useWindowSize();
  const [pillGroupKey, setPillGroupKey] = useState<number>(0);
  const [currentItem, setCurrentItem] =
    useState<CarouselItemType>(initialItemType);
  const dispatch = useAppDispatch();
  const visible = useAppSelector(getCarouselVisible);
  const toggleVisible = () => dispatch(setCarouselVisible(!visible));
  const closeOnOutsideClick = () => dispatch(setCarouselVisible(false));
  const { panoId, viewer } = useViewer();
  const media = useAppSelector((s) => s.media);
  const analyticsEvents = useAnalyticsEvents();
  const [showUp, setShowUp] = useState(false);
  const [showDown, setShowDown] = useState(true);
  const isLandscape = useIsMobile('landscape');
  const slidesPerView = { desktop: 6, mobile: 2 };

  // Mount and unmount active pill group to recalculate its dimensions on window resize
  useEffect(() => setPillGroupKey((key) => key + 1), [windowSize]);

  const setPano = useCallback(
    (pano: CarouselPano) => {
      dispatch(showPano());

      if (panoId !== pano.panoid) {
        viewer?.setPano(pano.panoid, { pov: pano.pov });
      }
    },
    [dispatch, panoId, viewer]
  );

  const setImage = useCallback(
    (image: CarouselImage) => {
      if (image.image === (media as Image).source) {
        return;
      }

      analyticsEvents.imageGalleryView(
        image.imageId,
        image.image.url,
        image.title
      );

      dispatch(
        showImage({
          imageId: image.imageId,
          imageTitle: image.title,
          source: image.image,
        })
      );
    },
    [analyticsEvents, dispatch, media]
  );

  const setVideo = useCallback(
    (video: CarouselVideo) => {
      if ((media as Video).source === video) {
        return;
      }

      analyticsEvents.videoGalleryView(video.videoId, video.title);

      dispatch(showVideo(video));
    },
    [analyticsEvents, dispatch, media]
  );

  const carouselRef = useRef<HTMLDivElement>(null);
  const containerTransition = useTransition(visible, {
    from: { translateX: '100%' },
    enter: { translateX: '0%' },
    leave: { translateX: '100%' },
  });

  const pillsTransitions = useTransition(visible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const pills: PillConfig<CarouselItemType>[] = useMemo(
    () =>
      [
        {
          key: 'pano' as CarouselItemType,
          display: <PanoIcon />,
        },
        ...(config.image.length > 0
          ? [
              {
                key: 'image' as CarouselItemType,
                display: <PhotoIcon />,
              },
            ]
          : []),
        ...(config.video.length > 0
          ? [
              {
                key: 'video' as CarouselItemType,
                display: <VideoIcon />,
              },
            ]
          : []),
      ].filter((pill) => availableTabs.includes(pill.key)),
    [availableTabs, config]
  );

  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, closeOnOutsideClick);

  return (
    <>
      {containerTransition(
        (style, item) =>
          item && (
            <animated.div
              style={style}
              className={styles.container}
              data-cy="carousel"
              id={CAROUSEL_ID}
              ref={containerRef}
            >
              <div
                className={styles.closeContainer}
                role="button"
                onClick={toggleVisible}
                data-cy="carousel-hamburger"
              >
                <Close className={styles.close} />
              </div>
              <div className={styles.imageContainer}>
                {themeType === 'light' ? (
                  <img src={logoLight} alt="Logo Light" />
                ) : (
                  <img src={logoDark} alt="Logo Dark" />
                )}
              </div>
              <div className={styles.controls}>
                {pillsTransitions(
                  (style, item) =>
                    item && (
                      <animated.div
                        style={style}
                        className={styles.pills}
                        id={TABS_ID}
                      >
                        <PillGroup<CarouselItemType>
                          key={pillGroupKey}
                          colors={pillColors}
                          pills={pills}
                          value={currentItem}
                          pillVariant="pro"
                          onChange={(newItem) => {
                            if (newItem === currentItem) return;
                            switch (newItem) {
                              case 'pano':
                                const [firstPano] = config.pano;
                                carouselRef?.current?.scroll(0, 0);
                                setPano(firstPano);
                                break;
                              case 'image':
                                const [firstImage] = config.image;
                                carouselRef?.current?.scroll(0, 0);
                                setImage(firstImage);

                                break;
                              case 'video':
                                const [firstVideo] = config.video;
                                carouselRef?.current?.scroll(0, 0);
                                setVideo(firstVideo);

                                break;
                            }

                            setCurrentItem(newItem);
                          }}
                        />
                      </animated.div>
                    )
                )}
              </div>

              <div className={clsx(styles.arrows, 'swiper-button-up')}>
                <ScrollArrow
                  className={UP_SCROLL_ARROW_CLASSNAME}
                  selectedType={media.type}
                  direction="up"
                  show={showUp}
                />
              </div>

              <Swiper
                className={styles.carousel}
                ref={carouselRef as unknown as React.RefObject<SwiperRef>}
                modules={[Navigation, Mousewheel]}
                id="carousel"
                direction={'vertical'}
                slidesPerView={
                  isLandscape ? slidesPerView.mobile : slidesPerView.desktop
                }
                navigation={{
                  enabled: true,
                  nextEl: '.swiper-button-down',
                  prevEl: '.swiper-button-up',
                }}
                spaceBetween={45}
                onReachBeginning={() => {
                  setShowUp(false);
                }}
                onReachEnd={() => {
                  setShowDown(false);
                }}
                onFromEdge={() => {
                  setShowUp(true);
                  setShowDown(true);
                }}
                mousewheel={true}
              >
                {currentItem === 'pano' &&
                  config.pano
                    .filter((pano) => pano.carousel)
                    .map((pano) => (
                      <SwiperSlide key={pano.panoid}>
                        <CarouselItem
                          active={
                            media.type === 'pano' && panoId === pano.panoid
                          }
                          title={pano.title}
                          thumbnail={pano.thumbnail.url}
                          onClick={() => setPano(pano)}
                          id={pano.panoid}
                          hasFloorplan={floorPlanPanoIds.includes(pano.panoid)}
                        />
                      </SwiperSlide>
                    ))}
                {currentItem === 'image' &&
                  config.image.map((image) => (
                    <SwiperSlide key={image.imageId}>
                      <CarouselItem
                        id={image.imageId}
                        active={(media as Image).source === image.image}
                        title={image.title}
                        thumbnail={image.thumbnail.url}
                        onClick={() => setImage(image)}
                      />
                    </SwiperSlide>
                  ))}
                {currentItem === 'video' &&
                  config.video.map((video) => (
                    <SwiperSlide key={video.videoId}>
                      <CarouselItem
                        id={video.videoId}
                        active={(media as Video).source === video}
                        title={video.title}
                        thumbnail={
                          video.type === 'tourbuilder'
                            ? video.thumbnail.url
                            : video.thumb
                        }
                        onClick={() => setVideo(video)}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
              <div className={clsx(styles.arrows, 'swiper-button-down')}>
                <ScrollArrow
                  className={DOWN_SCROLL_ARROW_CLASSNAME}
                  selectedType={media.type}
                  direction="down"
                  show={showDown}
                />
              </div>
            </animated.div>
          )
      )}
    </>
  );
}
