import { CSSProperties, useEffect, useState, useMemo } from 'react';

import useURLParam, {
  OverlayURLParam,
} from '../../hooks/useURLParam/useURLParam';
import { getCarouselVisible } from '../../stores/slices/ui';
import { MenuItemContentProvider } from './hooks/useMenuItemContent/useMenuItemContent';
import { ThemeProps } from '../Theme';
import { useAppSelector } from '../../hooks/redux';
import { useNavigateToPhoto } from '../../hooks/useNavigateToPhoto/useNavigateToPhoto';
import { useNavigateToVideo } from '../../hooks/useNavigateToVideo/useNavigateToVideo';
import Carousel from './components/Carousel/Carousel';
import MediaImage from '../../components/MediaImage/MediaImage';
import MediaImageControls from '../../components/MediaImageControls/MediaImageControls';
import MediaVideo from '../../components/MediaVideo/MediaVideo';
import Pano from './components/Pano/Pano';
import ShareLive from '../../components/ShareLive/ShareLive';
import StartScreen from '../../components/StartScreen/StartScreen';
import useTourFunctionality from '../../hooks/useTourFunctionality/useTourFunctionality';
import GlobalCss from './GlobalCss';
import useFireInitialAnalyticsEvents from '../../hooks/useFireInitialAnalyticsEvents/useFireInitialAnalyticsEvents';

import styles from './LivcorTheme.module.scss';

function LivcorTheme({ theme, tour }: ThemeProps) {
  const disableStartScreen =
    useURLParam(OverlayURLParam.START_SCREEN) === 'false';
  const hideRightMenu = useURLParam(OverlayURLParam.HIDE_RIGHT_MENU) === 'true';
  const hideShareLive = useURLParam(OverlayURLParam.HIDE_SHARE_LIVE) === 'true';
  const [showStartScreen, setShowStartScreen] = useState(
    tour.startScreen.show && !disableStartScreen
  );
  const { availableTabs, logo, isCarouselEnabled } = useTourFunctionality(
    tour,
    theme
  );
  const visible = useAppSelector(getCarouselVisible);
  const media = useAppSelector((s) => s.media);
  const fireInitialAnalyticsEvents = useFireInitialAnalyticsEvents();

  useEffect(() => {
    if (!showStartScreen) {
      fireInitialAnalyticsEvents();
    }
  }, [fireInitialAnalyticsEvents, showStartScreen]);

  const { navigateToPhoto: navigateToPhotoLeft } = useNavigateToPhoto(
    'left',
    tour.carousel.image
  );
  const { navigateToPhoto: navigateToPhotoRight } = useNavigateToPhoto(
    'right',
    tour.carousel.image
  );
  const { navigateToVideo: navigateToVideoLeft } = useNavigateToVideo(
    'left',
    tour.carousel.video
  );
  const { navigateToVideo: navigateToVideoRight } = useNavigateToVideo(
    'right',
    tour.carousel.video
  );

  const floorPlanPanoIds = useMemo(() => {
    return tour.floorplan.flatMap((floorplan) =>
      floorplan.hotspots.map((hotspot) => hotspot.panoid)
    );
  }, [tour.floorplan]);

  return (
    <>
      <GlobalCss />
      <MenuItemContentProvider>
        <div id="theme">
          {!hideShareLive && (
            <ShareLive
              clientId={tour.client._id}
              tourId={tour._id}
              themeId={tour.themeId}
            />
          )}
          {media.type === 'pano' && (
            <Pano hideRightMenu={hideRightMenu} tour={tour} theme={theme} />
          )}
          {media.type === 'image' && (
            <>
              <MediaImage
                key={media.source.url}
                config={media}
                showArrows={tour.carousel.image.length > 1}
                arrowVariant="side"
                onNavigateLeft={navigateToPhotoLeft}
                onNavigateRight={navigateToPhotoRight}
              />
              <MediaImageControls tour={tour} />
            </>
          )}
          {media.type === 'video' && (
            <MediaVideo
              arrowVariant="side"
              className={
                visible
                  ? styles.carouselVisibleVideo
                  : styles.carouselHiddenVideo
              }
              onNavigateLeft={navigateToVideoLeft}
              onNavigateRight={navigateToVideoRight}
              showArrows={tour.carousel.video.length > 1}
              source={media.source}
              tourId={tour._id}
            />
          )}
          {isCarouselEnabled && (
            <Carousel
              availableTabs={availableTabs}
              config={tour.carousel}
              pillColors={{
                default: theme.styles.carousel.tabs.icons.default.color,
                active: theme.styles.carousel.tabs.icons.active.color,
              }}
              floorPlanPanoIds={floorPlanPanoIds}
            />
          )}
          {showStartScreen && (
            <StartScreen
              config={tour.startScreen}
              onStart={() => {
                fireInitialAnalyticsEvents();
                setShowStartScreen(false);
              }}
              logo={logo}
              poweredBy={tour.poweredByLCP360}
            />
          )}
        </div>
      </MenuItemContentProvider>
    </>
  );
}

export function SquareLivcorTheme(props: ThemeProps) {
  return (
    <div
      data-cy="livcor-square-theme"
      style={
        {
          '--button-border-radius': 0,
          '--controls-border-radius': '5px',
        } as CSSProperties
      }
    >
      <LivcorTheme {...props} />
    </div>
  );
}

export function RoundedLivcorTheme(props: ThemeProps) {
  return (
    <div
      data-cy="livcor-rounded-theme"
      style={
        {
          '--button-border-radius': '5px',
          '--controls-border-radius': '50px',
        } as CSSProperties
      }
    >
      <LivcorTheme {...props} />
    </div>
  );
}
