import React, { useEffect, useMemo, useState, useRef } from 'react';
import styles from './AutoRotate.module.scss';

import useViewer from '../../hooks/useViewer/useViewer';
import ControlGroup from '../ControlGroup/ControlGroup';
import { VIEWER_CONTAINER_ID } from '../../constants';
import { Tour } from '../../types';

import { ReactComponent as Play } from './assets/play.svg';
import { ReactComponent as Pause } from './assets/pause.svg';
import { useAppSelector } from '../../hooks/redux';
import useMediaAutoplay from './hooks/useMediaAutoplay';
import { AUTOROTATE_VIEW_CONTROL_ID } from '../../constants/ids';

let timerId: number | null = null;

const ROTATE_INTERVAL = 30;
const ROTATE_STEP = 0.3;
const ROTATE_SLIDESHOW_SWITCH_POINT = 45;

interface AutoRotateProps {
  pauseIcon?: typeof Pause;
  playIcon?: typeof Play;
  tour: Tour;
  enabled: boolean;
  onChange: (value: boolean) => void;
  className?: string;
}

export default function AutoRotate({
  pauseIcon = Pause,
  playIcon = Play,
  tour,
  enabled,
  onChange,
  className = '',
}: AutoRotateProps) {
  const autoRotateMode = tour.autoRotateMode || 'slide-show';
  const degreesChangedRef = useRef(0);

  const [viewerClicked, setViewerClicked] = useState(false);
  const { viewer } = useViewer();

  const media = useAppSelector((state) => state.media);

  useEffect(() => {
    if (!viewer || media.type !== 'pano') return;
    if (enabled && timerId) return;

    const findNextPano = () => {
      const currentPanoId = viewer.getPano();

      const currentPanoIndex = tour.carousel.pano.findIndex(
        (pano) => pano.panoid === currentPanoId
      );

      const nextPanoIndex = (currentPanoIndex + 1) % tour.carousel.pano.length;

      return tour.carousel.pano[nextPanoIndex];
    };

    if (enabled) {
      timerId = window.setInterval(() => {
        const { heading, pitch, zoom } = viewer.getPov();

        viewer?.setPov({
          heading: heading + ROTATE_STEP,
          pitch,
          zoom,
        });

        if (autoRotateMode === 'slide-show') {
          degreesChangedRef.current += ROTATE_STEP;

          if (degreesChangedRef.current > ROTATE_SLIDESHOW_SWITCH_POINT) {
            degreesChangedRef.current = 0;

            const nextPano = findNextPano();
            viewer?.setPano(nextPano.panoid, {
              pov: nextPano.pov,
            });
          }
        }
      }, ROTATE_INTERVAL);
    }

    if (!enabled && timerId) {
      window.clearInterval(timerId);
      timerId = null;
    }
  }, [enabled, viewer, autoRotateMode, tour.carousel.pano, media.type]);

  useMediaAutoplay({ tour, enabled });

  useEffect(() => {
    const viewerContainer = document.getElementById(VIEWER_CONTAINER_ID);

    if (!viewerContainer) return;
    if (!enabled && !viewerClicked) return;

    function handleMouseDown() {
      onChange(false);
      setViewerClicked(true);
    }

    function handleMouseUp() {
      onChange(true);
      setViewerClicked(false);
    }

    viewerContainer.addEventListener('mousedown', handleMouseDown);
    viewerContainer.addEventListener('mouseup', handleMouseUp);

    return () => {
      viewerContainer.removeEventListener('mousedown', handleMouseDown);
      viewerContainer.removeEventListener('mouseup', handleMouseUp);
    };
  }, [enabled, onChange, viewerClicked]);

  const Icon = useMemo<typeof Play | typeof Pause>(() => {
    if (!viewerClicked) return enabled ? pauseIcon : playIcon;
    return enabled ? playIcon : pauseIcon;
  }, [enabled, pauseIcon, playIcon, viewerClicked]);

  return (
    <ControlGroup
      id={AUTOROTATE_VIEW_CONTROL_ID}
      className={`${styles.controlGroup} ${className}`}
      dataCy="auto-rotate-button"
      controls={[
        {
          display: <Icon aria-label="Autorotate" />,
          onClick: () => onChange(!enabled),
        },
      ]}
    />
  );
}
